import React from 'react';
import { useTranslation } from 'react-i18next';

import DefaultNavigation from '../DefaultPage/DefaultPageComponents/DefaultNavigation/DefaultNavigation';
import MarketHome from './MarketHome/MarketHome';
import MarketAbout from './MarketAbout/MarketAbout';
import MarketProjects from './MarketProjects/MarketProjects';
import Form from '../DefaultPage/DefaultPageComponents/Form/Form';
import Footer from '../DefaultPage/DefaultPageComponents/Footer/Footer';
import WhatsAppButton from '../DefaultPage/DefaultPageComponents/WhatsappBtn/WhatsappBtn';

const MarketPage = () => {

    const { t } = useTranslation();

    return (
        <>
            <DefaultNavigation
                navItems={[
                    {
                        label: t("marketingPage_nav.about"),
                        sectionId: "market_about_section",
                        type: "button",
                    },
                    {
                        label: t("marketingPage_nav.projects"),
                        sectionId: "market_projects_section",
                        type: "button",
                    },
                    // {
                    //   label: t("defaultPage_nav.contact"),
                    //   path: "/projects",
                    //   type: "link",
                    //   external: true,
                    // },
                ]}
            />
            <MarketHome />
            <MarketAbout />
            <MarketProjects />
            <Form />
            <Footer />
            <WhatsAppButton />

        </>
    );
};

export default MarketPage;