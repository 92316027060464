import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./form.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";

const Form = () => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  useEffect(() => {
    // Analytics tracking
    ReactGA.send({ hitType: "pageview", page: "/form-visited" });
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (!email || !lastName || !firstName || !contactNumber) {
      // Validation for empty fields
      toast.error("Please fill in all required fields.", {
        position: "top-center",
      });
      return;
    }

    // Prepare the payload for Bitrix24
    const payload = {
      fields: {
        NAME: firstName,
        LAST_NAME: lastName,
        EMAIL: [{ VALUE: email, VALUE_TYPE: "WORK" }],
        PHONE: [{ VALUE: contactNumber, VALUE_TYPE: "WORK" }],
        COMMENTS: message,
      },
    };

    try {
      // Send data to Bitrix24
      const response = await fetch(
        "https://b24-npfomz.bitrix24.com/rest/13/87zmityoh7f5ea3y/crm.lead.add.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();

      if (result.result) {
        toast.success("Form submitted successfully!", {
          position: "top-center",
        });

        // Reset form fields
        setFirstName("");
        setLastName("");
        setEmail("");
        setContactNumber("");
        setMessage("");
      } else {
        toast.error("Failed to submit form. Please try again.", {
          position: "top-center",
        });
        console.error("Bitrix Error:", result);
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: "top-center",
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: "white",
          fontFamily: "poppins",
          fontSize: "15px",
        }}
        progressStyle={{ background: "red", opacity: "unset" }}
      />

      <section id="form_section">
        <div id="form_txt_div">
          <h5 id="form_sbhdng">{t("defaultPage_contact.sub_heading")}</h5>
          <h1 id="form_hdng">{t("defaultPage_contact.main_heading")}</h1>

          <p id="form_txt">
            {t("defaultPage_contact.text")}
            <br />
            <b>{t("defaultPage_contact.highlight")}</b>
          </p>
        </div>

        <div id="form_div">
          <form ref={form} id="form" onSubmit={sendEmail}>
            <MDBRow className="mb-4">
              <MDBCol id="field">
                <MDBInput
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  id="first_name_input"
                  label="First Name"
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  id="last_name_input"
                  label="Last name"
                />
              </MDBCol>
            </MDBRow>
            <MDBInput
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mb-4"
              type="email"
              id="email_input"
              label="Email address"
              placeholder="123@abc.com"
            />

            <MDBInput
              name="contactNumber"
              value={contactNumber}
              onChange={(e) =>
                setContactNumber(e.target.value.replace(/^(\+92|0)?/, ""))
              }
              className="mb-4"
              type="text"
              id="phone_input"
              label="Contact No."
              placeholder="321 0000000"
            />

            <MDBInput
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="mb-4"
              type="text"
              id="msg_input"
              label="Your Message"
            />

            <MDBBtn
              type="submit"
              className="mb-4 submit_btn"
              block
              id="form_btn"
            >
              Submit
            </MDBBtn>
          </form>
        </div>
      </section>
    </>
  );
};

export default Form;
