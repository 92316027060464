import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

import "./ai-packages.css";

const AiPackages = () => {
  const { t } = useTranslation();

  const sectionScrollHandler = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const checkResponsive = () => {
    if (window.innerWidth < 450) {
      return false;
    } else if (window.innerWidth > 450) {
      return true;
    }
  };

  const [activeBtn, setActiveBtn] = useState("chatbot_btn");
  //   const [showForm, setShowForm] = useState(false); // State to toggle form modal visibility

  //   const toggleFormModal = () => {
  //     setShowForm(!showForm);
  //   };

  const Packages = {
    chatbot_btn: {
      heading: t("chatbotPage.packages.starter.heading"),
      pricing: t("chatbotPage.packages.starter.price"),
      description: t("chatbotPage.packages.starter.description"),
      retainer: t("chatbotPage.packages.starter.retainer"),
      points: [
        t("chatbotPage.packages.starter.points.0"),
        t("chatbotPage.packages.starter.points.1"),
        t("chatbotPage.packages.starter.points.2"),
        t("chatbotPage.packages.starter.points.3"),
        t("chatbotPage.packages.starter.points.4"),
        t("chatbotPage.packages.starter.points.5"),
        t("chatbotPage.packages.starter.points.6"),
        t("chatbotPage.packages.starter.points.7"),
        t("chatbotPage.packages.starter.points.8"),
      ]
    },
    voicebot_btn: {
      heading: t("chatbotPage.packages.growth.heading"),
      pricing: t("chatbotPage.packages.growth.price"),
      description: t("chatbotPage.packages.growth.description"),
      points: [
        t("chatbotPage.packages.growth.points.0"),
        t("chatbotPage.packages.growth.points.1"),
        t("chatbotPage.packages.growth.points.2"),
        t("chatbotPage.packages.growth.points.3"),
        t("chatbotPage.packages.growth.points.4"),
        t("chatbotPage.packages.growth.points.5"),
        t("chatbotPage.packages.growth.points.6"),
        t("chatbotPage.packages.growth.points.7"),
        t("chatbotPage.packages.growth.points.8"),
        t("chatbotPage.packages.growth.points.9"),
      ]
    },
    erp_btn: {
      heading: t("chatbotPage.packages.pro.heading"),
      pricing: t("chatbotPage.packages.pro.price"),
      description: t("chatbotPage.packages.pro.description"),
      points: [
        t("chatbotPage.packages.pro.points.0"),
        t("chatbotPage.packages.pro.points.1"),
        t("chatbotPage.packages.pro.points.2"),
        t("chatbotPage.packages.pro.points.3"),
        t("chatbotPage.packages.pro.points.4"),
        t("chatbotPage.packages.pro.points.5"),
        t("chatbotPage.packages.pro.points.6"),
        t("chatbotPage.packages.pro.points.7"),
        t("chatbotPage.packages.pro.points.8"),
        t("chatbotPage.packages.pro.points.9"),
        t("chatbotPage.packages.pro.points.10"),
        t("chatbotPage.packages.pro.points.11"),
        t("chatbotPage.packages.pro.points.12"),
        t("chatbotPage.packages.pro.points.13"),
        t("chatbotPage.packages.pro.points.14"),
      ]
    },
  };


  return (
    <section id="ai_packages_section">
      <div id="ai_packages_txt_div">
        <span>
          <h3 id="ai_packages_hdng">{t("chatbotPage.packages.heading")}</h3>
          <p id="ai_packages_txt">{t("chatbotPage.packages.text")}</p>
        </span>
        <button
          id="ai_learn_btn"
          onClick={() => {
            sectionScrollHandler("form_section");
          }}
        >
          {t("chatbotPage.packages.button")}
        </button>
      </div>

      <div id="ai_packages_content_div">
        <div id="ai_modules_div">
          <h3 id="ai_modules_hdng">{t("chatbotPage.packages.highlight")}</h3>
          <div id="selection_btns_div">
            <button
              className={`selection_btn ${activeBtn === "chatbot_btn" ? "selected" : ""
                }`}
              id="chatbot_btn"
              onClick={() => setActiveBtn("chatbot_btn")}
            >
              {t("chatbotPage.packages.starter.heading")}
            </button>
            <button
              className={`selection_btn ${activeBtn === "voicebot_btn" ? "selected" : ""
                }`}
              id="voicebot_btn"
              onClick={() => setActiveBtn("voicebot_btn")}
            >
              {t("chatbotPage.packages.growth.heading")}
            </button>
            <button
              className={`selection_btn ${activeBtn === "erp_btn" ? "selected" : ""
                }`}
              id="erp_btn"
              onClick={() => setActiveBtn("erp_btn")}
            >
              {t("chatbotPage.packages.pro.heading")}
            </button>
          </div>
        </div>

        <div id="ai_packages_details_div">
          <h3 className="ai_pkg_hdng">{Packages[activeBtn].heading}</h3>
          {/* <p className="ai_pkg_price" id="goals_txt">
            {Packages[activeBtn].pricing}
            <br />
            {Packages[activeBtn].retainer}
          </p> */}

          <p className="ai_pkg_txt" id="pains_txt">
            {Packages[activeBtn].description}
          </p>

          <div id="ai_pkg_points">
            {Packages[activeBtn].points.map((point, index) => (
              <span className="points" key={index}>
                <FaRegCheckCircle
                  style={{ color: "#EF4723", fontSize: "17px", marginRight: "7px" }}
                />
                {point}
              </span>
            ))}
          </div>

        </div>
      </div>
    </section>
  );
};

export default AiPackages;
